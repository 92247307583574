import { dynamicSort } from "../components/Helpers/HelperFunction";
import { getHierarchicalTags } from "../components/ResourcePath/Resources/QuizResource/functions/QuizHelperFunctions";

export function getSpaceData(space) {
    //METADATA
    const spc_meta = space?.metaData;
    const spc_title = spc_meta?.title;
    const spc_subTitle = spc_meta?.subTitle;
    const spc_favicon = spc_meta?.favicon;
    const spc_bannerColor = spc_meta?.ui?.bannerColor;
    const spc_home_page = spc_meta?.homePage;
    const spc_url = spc_meta?.url;

    const spc_path_ids = space?.content?.path_ids;
    const spc_paths_object = space?.content?.paths;
    const spc_paths = spc_paths_object ? Object.values(spc_paths_object) : [];
    const spc_paths_sorted = spc_paths?.sort(dynamicSort("title"));
    const spc_paths_published = spc_paths_sorted?.filter((p) => !p.not_published);

    //ABOUT
    const spc_aboutTitle = space?.about?.aboutTitle;
    const spc_about_stats = space?.about?.stats ? space?.about?.stats?.sort(dynamicSort("position")) : [];
    const spc_about_descr = space?.about?.description;

    //UI
    const spc_resourceBrowse_bannerText = space?.browseBanner?.resourceBrowse_bannerText;
    const spc_resourceBrowse_bannerSubText = space?.browseBanner?.resourceBrowse_bannerSubText;
    const spc_pathBrowse_bannerText = space?.browseBanner?.pathBrowse_bannerSubText;
    const spc_pathBrowse_bannerSubText = space?.browseBanner?.pathBrowse_bannerText;

    //MEMBERS & ROLES
    const spc_curator_ids = space?.roles?.curator_ids ? space?.roles?.curator_ids : [];

    //** THEME */
    //main
    const main_color = space?.main?.main_color;
    const main_accent = space?.main?.main_accent;
    const main_font_color = space?.main?.main_font_color;
    //Main Navigation Bar
    const mainNav_backgroundColor = space?.mainNav?.mainNav_backgroundColor;
    const mainNav_middleBtnColor = space?.mainNav?.mainNav_middleBtnColor;
    const mainNav_middleBtnColorActive = space?.mainNav?.mainNav_middleBtnColorActive;
    const mainNav_middleBtnHover = space?.mainNav?.mainNav_middleBtnHover;

    //Big Chat
    const chatRooms_background = space?.bigChat?.chatRooms_background;
    const chatRooms_roomActive = space?.bigChat?.chatRooms_roomActive;
    const chatRooms_horizontalLine = space?.bigChat?.chatRooms_horizontalLine;

    //Browse Banner
    const browseBanner_mainColor = space?.browseBanner?.browseBanner_mainColor;
    const browseBanner_accentColor = space?.browseBanner?.browseBanner_accentColor;

    const spc_login_img = space?.login?.loginImage;

    //CONFIG
    const spc_config = space?.config;
    const spc_avail_languages = spc_config?.avail_languages || ["en", "de"];
    const spc_default_language = spc_config?.default_language ? spc_config?.default_language : "en";
    const spc_signUpEnabled = spc_config?.signUpEnabled;
    const spc_signInEnabled = spc_config?.signInEnabled;
    const spc_sales_pay = spc_config?.pay ? spc_config?.pay : false;

    //SECURITY
    const spc_no_of_sessions_isLimited = typeof spc_config?.no_of_sessions_isLimited === "boolean" ? spc_config?.no_of_sessions_isLimited : false;
    const spc_no_of_sessions_allowed = spc_config?.no_of_sessions_allowed ? spc_config?.no_of_sessions_allowed : 3;
    const spc_pdfs_are_secured = typeof spc_config?.pdfs_are_secured === "boolean" ? spc_config?.pdfs_are_secured : false;
    const spc_richtexts_are_secured = typeof spc_config?.richtexts_are_secured === "boolean" ? spc_config?.richtexts_are_secured : false;
    return {
        //METADATA
        spc_title,
        spc_subTitle,
        spc_favicon,
        ////IMAGES
        spc_bannerColor,
        spc_home_page,
        spc_url,

        spc_path_ids,
        spc_paths,
        spc_paths_sorted,
        spc_paths_published,
        spc_paths_object,
        //ABOUT
        spc_aboutTitle,
        spc_about_stats,
        spc_about_descr,
        //UI
        spc_pathBrowse_bannerText,
        spc_pathBrowse_bannerSubText,
        spc_resourceBrowse_bannerText,
        spc_resourceBrowse_bannerSubText,
        //MEMBERS
        spc_curator_ids,
        //THEME
        main_color,
        main_accent,
        main_font_color,
        browseBanner_mainColor,
        browseBanner_accentColor,
        //mainNav
        mainNav_backgroundColor,
        mainNav_middleBtnColor,
        mainNav_middleBtnColorActive,
        mainNav_middleBtnHover,
        //bigchat
        chatRooms_background,
        chatRooms_roomActive,
        chatRooms_horizontalLine,
        //sideNav
        spc_login_img,
        //CONFIG
        spc_default_language,
        spc_avail_languages,
        spc_signUpEnabled,
        spc_signInEnabled,
        spc_no_of_sessions_isLimited,
        spc_no_of_sessions_allowed,
        spc_pdfs_are_secured,
        spc_richtexts_are_secured,
        spc_sales_pay,
    };
}

export function getSpaceThemeData(space_theme_data) {
    //UI
    const space_theme = space_theme_data?.theme;
    const spc_resourceBrowse_bannerText = space_theme?.browseBanner?.resourceBrowse_bannerText;
    const spc_resourceBrowse_bannerSubText = space_theme?.browseBanner?.resourceBrowse_bannerSubText;
    const spc_pathBrowse_bannerText = space_theme?.browseBanner?.pathBrowse_bannerSubText;
    const spc_pathBrowse_bannerSubText = space_theme?.browseBanner?.pathBrowse_bannerText;
    //Browse Banner
    const browseBanner_mainColor = space_theme?.browseBanner?.browseBanner_mainColor;
    const browseBanner_accentColor = space_theme?.browseBanner?.browseBanner_accentColor;

    //** THEME */
    //LOGOS
    const spc_logo = space_theme?.images?.space_logo;
    const spc_icon = space_theme?.images?.space_icon;
    const spc_logo_font = space_theme?.images?.space_logo_font;

    //main
    const main_color = space_theme?.main?.main_color;
    const main_accent = space_theme?.main?.main_accent;
    const main_font_color = space_theme?.main?.main_font_color;
    //Main Navigation Bar
    const mainNav_backgroundColor = space_theme?.mainNav?.mainNav_backgroundColor;
    const mainNav_middleBtnColor = space_theme?.mainNav?.mainNav_middleBtnColor;
    const mainNav_middleBtnColorActive = space_theme?.mainNav?.mainNav_middleBtnColorActive;
    const mainNav_middleBtnHover = space_theme?.mainNav?.mainNav_middleBtnHover;

    //Big Chat
    const chatRooms_background = space_theme?.bigChat?.chatRooms_background;
    const chatRooms_roomActive = space_theme?.bigChat?.chatRooms_roomActive;
    const chatRooms_horizontalLine = space_theme?.bigChat?.chatRooms_horizontalLine;

    return {
        //Logo
        spc_logo,
        spc_icon,
        spc_logo_font,
        //UI
        browseBanner_mainColor,
        browseBanner_accentColor,
        spc_pathBrowse_bannerText,
        spc_pathBrowse_bannerSubText,
        spc_resourceBrowse_bannerText,
        spc_resourceBrowse_bannerSubText,
        //THEME
        main_color,
        main_accent,
        main_font_color,

        //mainNav
        mainNav_backgroundColor,
        mainNav_middleBtnColor,
        mainNav_middleBtnColorActive,
        mainNav_middleBtnHover,
        //bigchat
        chatRooms_background,
        chatRooms_roomActive,
        chatRooms_horizontalLine,
    };
}
export function getSpaceEmailData(data) {
    const spc_email_config = data?.config;
    const spc_lead_email_active = spc_email_config?.first;
    const spc_follow_lead_email_active = spc_email_config?.follow;
    const spc_last_lead_email_active = spc_email_config?.last;
    const spc_purchase_email_active = spc_email_config?.purchase;
    const spc_email_texts = data?.texts;
    const spc_email_subjects = data?.subjects;

    const spc_lead_email_text = spc_email_texts?.first;
    const spc_purchase_email_text = spc_email_texts?.purchase;
    const spc_lead_email_subject = spc_email_subjects?.first;
    const spc_purchase_email_subject = spc_email_subjects?.purchase;
    const spc_followlead_email_text = spc_email_texts?.follow;
    const spc_follow_lead_email_subject = spc_email_subjects?.follow;
    const spc_last_lead_email_text = spc_email_texts?.last;
    const spc_last_lead_email_subject = spc_email_subjects?.last;
    const spc_promo_lead_email_text = spc_email_texts?.promo; 
    const spc_promo_lead_email_subject = spc_email_subjects?.promo;
    const spc_promo_lead_email_active = spc_email_config?.promo;
    


    return {
        spc_lead_email_active, spc_purchase_email_active, spc_follow_lead_email_active,
        spc_last_lead_email_active,
        spc_lead_email_text, spc_purchase_email_text,
        spc_lead_email_subject, spc_purchase_email_subject,
        spc_followlead_email_text, spc_follow_lead_email_subject,
        spc_last_lead_email_text, spc_last_lead_email_subject,
        spc_promo_lead_email_text, spc_promo_lead_email_subject, spc_promo_lead_email_active
    }
}

export function getSpaceSalesData(space_sales) {
    const spc_products = space_sales?.products || [];
    const spc_product_upgrade = spc_products?.find(p => p.upgradeToBundle);
    const spc_product_pq = spc_products?.find(p => p.type === "pq");
    const spc_product_bundle = spc_products?.find(p => p.full_bundle);

    const spc_customer_portal_link = space_sales?.customer_portal?.link || "";
    const spc_promo_code = space_sales?.promo_code || "";

    return {
        spc_products, spc_customer_portal_link, spc_product_upgrade, spc_product_pq,
        spc_product_bundle, spc_promo_code
    }
}


export function getSpaceInfoData(space) {
    //METADATA

    //ABOUT
    const spc_aboutTitle = space?.about?.aboutTitle;
    const spc_about_stats = space?.about?.stats ? space?.about?.stats?.sort(dynamicSort("position")) : [];
    const spc_about_descr = space?.about?.description;
    const spc_aboutImage = space?.about?.images?.aboutImage;
    const spc_bannerImg = space?.about?.images?.bannerImg;
    const spc_support_mail = space?.support?.email;

    return {
        //ABOUT
        spc_aboutTitle,
        spc_about_stats,
        spc_about_descr,
        //images
        spc_aboutImage,
        spc_bannerImg,
        spc_support_mail,
    };
}

export function getTaskData(task, checked_tasks_rsrc = []) {
    const task_id = task?.id;
    const task_text = task?.title;
    const task_time = task?.time;
    const task_isChecked = checked_tasks_rsrc?.includes(task_id);

    return {
        task_id,
        task_text,
        task_time,
        task_isChecked,
    };
}

export function getPQCatalogData(pq_catalog, tabLevel1 = null, tabLevel2 = null, pq_qids_per_tags = {}, all_qids = []) {
    const pq_path_id = pq_catalog?.path_id;
    const pq_hierarchy_1_object = pq_catalog?.hierarchy_1 ? pq_catalog?.hierarchy_1 : {};
    const pq_hierarchy_1_keys = Object.keys(pq_hierarchy_1_object);

    const pq_hierarchy_1 = pq_hierarchy_1_object ? Object.values(pq_hierarchy_1_object) : [];
    const pq_hierarchy_1_sorted = pq_hierarchy_1?.sort(dynamicSort("position"));
    const pq_hierarchy_tags_1 = pq_hierarchy_1?.map((hier) => hier.key);

    const pq_hierarchy_2_object = pq_catalog?.hierarchy_2 ? pq_catalog?.hierarchy_2 : {};
    const pq_hierarchy_2 = pq_hierarchy_2_object?.[tabLevel1] ? Object.values(pq_hierarchy_2_object[tabLevel1]) : [];
    const pq_hierarchy_tags_2 = pq_hierarchy_2?.map((hier) => hier.key);
    const pq_hierarchy_2_keys = pq_hierarchy_2_object?.[tabLevel1] ? Object.keys(pq_hierarchy_2_object?.[tabLevel1]) : [];
    const pq_hierarchy_2_sorted = pq_hierarchy_2?.sort(dynamicSort("position"));
    const next_position_2 = pq_hierarchy_2_sorted?.length > 0 ? pq_hierarchy_2_sorted[pq_hierarchy_2_sorted?.length - 1]?.position + 1 : 1;

    const pq_hierarchy_3_object = pq_catalog?.hierarchy_3 ? pq_catalog?.hierarchy_3 : {};
    const pq_hierarchy_3 = pq_hierarchy_3_object?.[tabLevel1]?.[tabLevel2] ? Object.values(pq_hierarchy_3_object[tabLevel1][tabLevel2]) : [];
    const pq_hierarchy_tags_3 = pq_hierarchy_3?.map((hier) => hier.key);
    const pq_hierarchy_3_keys = pq_hierarchy_3_object?.[tabLevel1]?.[tabLevel2] ? Object.keys(pq_hierarchy_3_object[tabLevel1][tabLevel2]) : [];
    const pq_hierarchy_3_sorted = pq_hierarchy_3?.sort(dynamicSort("position"));
    const next_position_3 = pq_hierarchy_3_sorted?.length > 0 ? pq_hierarchy_3_sorted[pq_hierarchy_3_sorted?.length - 1]?.position + 1 : 1;

    const pq_empty_catalog = pq_hierarchy_1?.length === 0;
    const hierarchicalTags = getHierarchicalTags(pq_hierarchy_3_object);

    //Other fields
    //BRING ALL INTO CONTEXT
    const qids_other_field_1 = getOtherQIDS(pq_hierarchy_tags_1, pq_qids_per_tags, all_qids); //all QIDS minus all tabLevel1 QIDS
    const qids_other_field_2 = tabLevel1
        ? getOtherQIDS(
            pq_hierarchy_tags_2,
            pq_qids_per_tags,
            all_qids?.filter((qid) => pq_qids_per_tags?.[tabLevel1]?.includes(qid))
        )
        : []; //all tabLevel1 QIDS minus all pq_hierarchy_tags_2 QIDS
    const qids_other_field_3 = tabLevel2
        ? getOtherQIDS(
            pq_hierarchy_tags_3,
            pq_qids_per_tags,
            all_qids?.filter((qid) => pq_qids_per_tags?.[tabLevel1]?.includes(qid) && pq_qids_per_tags?.[tabLevel2]?.includes(qid))
        )
        : []; //all tabLevel2 QIDS minus all pq_hierarchy_tags_3 QIDS

    return {
        pq_path_id,
        pq_hierarchy_1_object,
        pq_hierarchy_1,
        pq_hierarchy_1_sorted,
        pq_hierarchy_1_keys,
        pq_hierarchy_2_object,
        pq_hierarchy_2,
        pq_hierarchy_2_sorted,
        next_position_2,
        pq_hierarchy_2_keys,
        pq_hierarchy_3_object,
        pq_hierarchy_3,
        pq_hierarchy_3_sorted,
        next_position_3,
        pq_hierarchy_3_keys,
        pq_empty_catalog,
        hierarchicalTags,
        qids_other_field_1,
        qids_other_field_2,
        qids_other_field_3,
    };
}

export function getOtherQIDS(tags = [], pq_qids_per_tags = {}, all_qids = []) {
    const qids_in_set_tags = [];
    tags.forEach((tag) => {
        const tag_qids = pq_qids_per_tags?.[tag] ? pq_qids_per_tags[tag] : [];
        qids_in_set_tags.push(...tag_qids);
    });
    const remaining_qids = all_qids.filter((qid) => !qids_in_set_tags.includes(qid));
    return remaining_qids;
}

//USER
export function getUserPathProgressData(users_path_progress) {
    const users_checked_path_resources = users_path_progress?.resources?.checked_path_resources || [];
    const users_checked_path_resource_tasks = users_path_progress?.tasks?.checked_path_resource_tasks;
    const time_worked = users_path_progress?.stats?.time_worked;
    const checked_path_resources_count = users_path_progress?.stats?.checked_path_resources_count;
    const checked_path_tasks_count = users_path_progress?.stats?.checked_path_tasks_count;
    const checked_path_resources_by_tag_date = users_path_progress?.tags?.checked_path_resources_by_tag_date;

    //voting:
    const local_upvotes = users_path_progress?.voting?.upvotes;
    const local_downvotes = users_path_progress?.voting?.downvotes;

    return {
        users_checked_path_resources,
        users_checked_path_resource_tasks,
        time_worked,
        checked_path_resources_count,
        checked_path_tasks_count,
        checked_path_resources_by_tag_date,
        local_upvotes,
        local_downvotes,
    };
}

export function getProductData(product) {
    const pr_dscr = product.description;
    const pr_name = product.name;
    const pr_active = product.active;

    const pr_payment_link = product.payment_link;

    //metadata
    const pr_path_ids = product?.metadata?.path_ids ? product?.metadata?.path_ids : [];
    const pr_id = product?.metadata?.id;
    const pr_is_bundle = product?.metadata?.bundle;

    return {
        pr_dscr,
        pr_name,
        pr_active,
        pr_payment_link,
        //metadata
        pr_path_ids,
        pr_id,
        pr_is_bundle,
    };
}
