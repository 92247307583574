import { useEffectOnce, useLocalStorage, useUpdateEffect } from "react-use";
import { createContext, useContext, useEffect, useLayoutEffect, useState } from "react";

import { checkIsMobile, determineSpaceID } from "./AppInitContextFunctions";
import { setFaviconAndPageTitle } from "../../functions/SpaceConfigFunctions";
import { getDeviceType, regulateNoOfConnections } from "../../components/Helpers/RealTimeDBFunctions";

import { makeid } from "../../components/Helpers/HelperFunction";

import useSpaceData from "../../hooks/spaceHooks/useSpaceData";
import useSpaceMap from "../../pages/Chat/hooks/spaceHooks/useSpaceMap";
import useSpaceStats from "../../hooks/spaceHooks/useSpaceStats";
import useUserConnections from "../../pages/Chat/hooks/realTimeDBHooks/useUserConnections";
import useSpaceThemeData from "../../hooks/spaceHooks/useSpaceThemeData";
import useSpaceInfoData from "../../hooks/spaceHooks/useSpaceInfoData";
import setSpaceTheme from "../../functions/setSpaceTheme";
import setManifestFile from "../../functions/setManifestFile";
import useSpaceSalesData from "../../hooks/spaceHooks/useSpaceSalesData";

export const SpaceContext = createContext({
    spc_lang: "", //TESTING:
    space_id: "",
    loading_space_map: false,
    isMobile: false,
    //SPACE DATA
    //APP CONFIG
    spc_signUpEnabled: false,
    spc_signInEnabled: false,
    spc_sales_pay: false,
    //space DATA
    space: {},
    space_theme: {},
    space_info: {},
    spc_curator_ids: [],
    loading_spaceData: false,
    spc_logo: "",
    spc_icon: "",
    spc_logo_font: "",
    spc_home_page: "",
    spc_url: "",
    spc_title: "",
    spc_subTitle: "",
    spc_default_language: "",
    spc_avail_languages: [],
    spc_paths_object: {},
    spc_paths: [],
    spc_paths_sorted: [],
    spc_paths_published: [],
    spc_path_ids: [],
    spc_active_group_ids: [],
    spc_paths_active_count: 0,
    space_votes_per_path: {},

    spc_curatordashboard_enabled: false,
    spc_richtexts_are_secured: false,
    spc_bannerImg: "",
    spc_bannerColor: "",
    spc_aboutTitle: "",
    spc_aboutImage: "",
    spc_about_stats: [],
    spc_about_descr: "",
    spc_support_mail: "",
    main_color: "",
    main_accent: "",
    mainNav_backgroundColor: "#fff",
    loaded_RT_Members: {},
    setloaded_RT_Members: null,
    //CONFIG
    has_internet: true,
    //security
    too_many_sessions: false,
    active_devices: [],
    device_type: "",
    rtdb_connections: {},
    sessions_over_limit: 0,
    spc_no_of_sessions_allowed: 5,
    spc_pdfs_are_secured: false,
});

export function useSpaceContext() {
    return useContext(SpaceContext);
}

//provider
export function SpaceProvider({ children }) {
    const [space_id, setSpace_id] = useState(null); //loadingSpace

    // console.log("APP_INIT - Context render");

    const [appVersion, setAppVersion] = useLocalStorage("RADIX_APP_VERSION", "0");
    useEffect(() => {
        const currentAppVersion = process.env.REACT_APP_VERSION;
        if (appVersion !== currentAppVersion) {
            // window.localStorage.clear();
            // window.sessionStorage.clear();
            // sessionStorage.clear();
            setAppVersion(currentAppVersion);
        }
        // console.log("APPVERSION", currentAppVersion, appVersion);
    }, []);

    //STEP 0 determine if device is mobile or not
    const device_type = getDeviceType();
    const isMobile = device_type === "mobile" || checkIsMobile();
    useEffectOnce(() => {
        const vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        window.addEventListener("resize", () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
        });
        return () => {
            window.removeEventListener("resize", () => {});
        };
    });

    const [not_inside_static_map, setnot_inside_static_map] = useState(false);
    //SPACE MAP
    const { space_map_loaded, loading_space_map } = useSpaceMap(not_inside_static_map); //only load when domain not in static!
    const static_map = {
        bemedi: "bemedi",
        "be-medi": "bemedi",
        "med-bee": "medbee",
        medimario: "medimario",
        rdx: "RDX",
        app: "RADIX",
        "localhost:3000": "medimario",
        "localhost:3001": "medbee",
        "localhost:5001": "medimario"
    };

    const lang_static_map = {
        medimario: "en",
        bemedi: "en",
        medbee: "en",
        "localhost:3000": "en"
    };

    const space_map = space_map_loaded ? space_map_loaded : static_map;
    //STEP 1
    useEffect(() => {
        if (space_map) {
            setSpace_id(determineSpaceID(space_map, not_inside_static_map, setnot_inside_static_map));
        }
    }, [space_map]);

    //SPACE DATA

    //SPACE CONFIG
    const spaceData = useSpaceData(space_id);

    const spc_google_login = space_id !== "8mxtChechI0CA1M";

    //SPACE Data loading
    const spaceThemeData = useSpaceThemeData(space_id);

    useEffect(() => {
        if (!spaceThemeData?.loading_spaceThemeData && spaceThemeData) {
            setSpaceTheme(spaceThemeData);
        }
    }, [spaceThemeData?.loading_spaceThemeData, spaceThemeData]);

    useEffect(() => {
        if (!spaceThemeData?.loading_spaceThemeData && spaceThemeData && spaceData && !spaceData?.loading_spaceData) {
            setManifestFile(spaceThemeData, spaceData);
        }
    }, [spaceThemeData?.loading_spaceThemeData, spaceThemeData, spaceData]);

    const spaceStatData = useSpaceStats(space_id);
    const spaceSalesData = useSpaceSalesData(space_id);


    const { space_info, spc_aboutTitle, spc_about_stats, spc_about_descr, spc_aboutImage, spc_bannerImg, spc_support_mail } =
        useSpaceInfoData(space_id);
    //Stats

    //* PAGES CONFIG


    //OTHER

    //STATUS
    // NO. OF CONNECTIONS
    const { active_devices, no_of_active_devices, loading_presence, rtdb_connections, user_connections } = useUserConnections(space_id);
    const [device_id, setConID] = useLocalStorage("device_id", device_type + "_" + makeid(4));
    const [has_internet, sethas_internet] = useState(true);
    useUpdateEffect(() => {
        if (space_id && sethas_internet) {
            const new_tab_id = makeid(2);
            regulateNoOfConnections(space_id, device_id, new_tab_id, sethas_internet);
        }
    }, [sethas_internet, space_id]);

    const too_many_sessions = spaceData.spc_no_of_sessions_isLimited && no_of_active_devices > spaceData.spc_no_of_sessions_allowed;
    const sessions_over_limit = too_many_sessions && no_of_active_devices - spaceData.spc_no_of_sessions_allowed;
    // console.log("INIT SESSIONS", no_of_active_devices, spc_no_of_sessions_allowed, spc_no_of_sessions_isLimited);
    const [loaded_RT_Members, setloaded_RT_Members] = useState({});

    useUpdateEffect(() => {
        //Set favicon and page title
        if (spaceData.spc_title && spaceData.spc_subTitle && spaceData.spc_favicon) {
            setFaviconAndPageTitle(spaceData.spc_title, spaceData.spc_subTitle, spaceData.spc_favicon);
        }
    }, [spaceData.spc_title, spaceData.spc_subTitle, spaceData.spc_favicon]);

    const spc_lang = spaceData.spc_default_language ? spaceData.spc_default_language : lang_static_map[space_id];

    const value = {
        spc_lang, //TESTING:
        space_id,
        loading_space_map,
        isMobile,
        //SPACE DATA
        //APP CONFIG
        ...spaceData,
        // THEME DATA
        ...spaceThemeData,
        // STAT DATA
        ...spaceStatData,
        // SALES 
        ...spaceSalesData,
        //IMAGES
        spc_bannerImg,
        //ABOUT
        space_info,
        spc_about_descr,
        spc_about_stats,
        spc_aboutTitle,
        spc_aboutImage,
        //OTHER
        //
        spc_support_mail,
        //UI
        //THEME

        //sideNav

        //CONFIG

        spc_google_login,
        //CONNECTIONS

        no_of_active_devices,
        sessions_over_limit,
        rtdb_connections,
        too_many_sessions,
        device_type,
        active_devices,
        device_id,
        has_internet,
        //RT_MEMBERS
        loaded_RT_Members,
        setloaded_RT_Members,
    };
    return <SpaceContext.Provider value={value}>{children}</SpaceContext.Provider>;
}
