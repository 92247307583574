
export function getMemberData(member) {
    //user
    const user_name = member?.user?.name;
    const user_email = member?.user?.email;

    const user_id = member?.user?.user_id;
    const user_pic = member?.user?.user_pic;
    const user_background = member?.user?.background?.profession
    const user_role = member?.user?.role;
    //Products
    const m_products = member?.products;
    const m_products_purchased = m_products?.purchased;
    const m_products_full_bundle = m_products?.full_bundle;

    //access
    //Paths
    const m_paths = member?.paths;
    const m_left_paths = m_paths?.left_paths ? m_paths.left_paths : {};
    const m_joined_paths = m_paths?.joined_paths ? m_paths.joined_paths : {};
    const m_active_curator_path_ids = m_paths?.active_curator_path_ids || [];
    const m_active_teacher_path_ids = m_paths?.active_teacher_path_ids || [];
    const m_active_student_path_ids = m_products_full_bundle ? m_paths?.active_student_path_ids || [] : [];
    const m_pending_student_path_ids = m_paths?.pending_student_path_ids;
    const m_deleted_student_path_ids = m_paths?.deleted_student_path_ids;
    const m_active_student_pq_ids = m_products_purchased ? m_paths?.active_student_path_ids || [] : [];


    //Groups
    const m_groups = member?.groups;
    const m_joined_groups = m_groups?.joined_groups ? m_groups.joined_groups : {};
    const m_left_groups = m_groups?.left_groups ? m_groups.left_groups : {};
    const m_active_teacher_group_ids = m_groups?.active_teacher_group_ids ? m_groups?.active_teacher_group_ids : [];
    const m_active_student_group_ids = m_groups?.active_student_group_ids ? m_groups?.active_student_group_ids : [];

    //metadata
    const m_metaData = member?.metaData;
    const m_joined_space = m_metaData?.date_joined_space;
    const m_access_start = m_metaData?.access_start;

    //OTHER
    const user_has_profile = member?.user?.has_c_profile;
    //contact
    const user_contact_status = member?.contact?.status || "-"

    return {
        //user
        user_name, user_email, user_id, user_pic, user_background, user_role,
        //access
        user_has_profile,
        m_paths, m_left_paths, m_joined_paths,
        m_active_curator_path_ids,
        m_active_teacher_path_ids,
        m_active_student_path_ids, m_active_student_pq_ids,
        m_groups, m_left_groups, m_joined_groups,
        m_active_teacher_group_ids,
        m_active_student_group_ids,
        m_pending_student_path_ids, m_deleted_student_path_ids,
        m_joined_space, m_access_start,
        //products
        m_products_purchased, m_products_full_bundle,
        user_contact_status
    }
}

export function getMemberDataByRole(member, role) {
    //user
    const user_name = member?.user?.name;
    const user_id = member?.user?.user_id;

    //access
    //Paths
    const m_paths = member?.paths;
    const m_active_path_ids = m_paths?.["active_" + role + "_path_ids"];
    const m_inactive_path_ids = m_paths?.["inactive_" + role + "_path_ids"];
    const m_deleted_path_ids = m_paths?.["deleted_" + role + "_path_ids"];
    const m_left_paths = m_paths?.left_paths;
    //Groups
    const role_corrected = role === "curator" ? "teacher" : role;
    const m_groups = member?.groups;
    const m_active_group_ids = m_groups?.["active_" + role_corrected + "_group_ids"];
    const m_archived_group_ids = m_groups?.["archived_" + role_corrected + "_group_ids"];
    const m_deleted_group_ids = m_groups?.["deleted_" + role_corrected + "_group_ids"] ? m_groups?.["deleted_" + role_corrected + "_group_ids"] : [];

    const m_left_groups = m_groups?.left_groups;

    return {
        //user
        user_name, user_id,
        //access
        m_paths,
        m_active_path_ids,
        m_inactive_path_ids,
        m_deleted_path_ids,
        m_left_paths,
        m_groups,
        m_active_group_ids, m_archived_group_ids, m_deleted_group_ids,
        m_left_groups
    }
}


export function getGroupData(group) {
    const g_color = group?.properties?.color;
    const g_id = group?.metaData?.id;
    const g_name = group?.metaData?.name;
    const g_status = group?.metaData?.status;
    const g_isArchived = g_status === "archived"
    const g_path_ids = group?.content?.path_ids;
    const g_resourcesPerPath = group?.content?.resources;
    //stats
    const g_message_count = group?.stats?.message_count ? group.stats.message_count : 0;
    const g_resources_message_count = group?.stats?.resources_message_count ? group.stats.resources_message_count : {};
    const g_deleted_message_count = group?.stats?.deleted_message_count ? group.stats.deleted_message_count : 0;
    return {
        g_color,
        g_name, g_id,
        g_status,
        g_isArchived,
        g_message_count, g_resources_message_count, g_deleted_message_count,
        g_path_ids,
        g_resourcesPerPath
    }
}

export function getGroupMemberStats(stats) {
    const g_student_ids = stats?.stats?.members?.student_ids ? stats.stats.members.student_ids : [];
    const g_student_count = g_student_ids?.length;
    const g_teacher_ids = stats?.stats?.members?.teacher_ids ? stats.stats.members.teacher_ids : [];
    const g_teacher_count = g_teacher_ids?.length;
    const all_active_member_ids = g_teacher_count > 0 ? g_teacher_ids?.concat(g_student_ids) : g_student_ids

    return {
        g_student_count, g_student_ids,
        g_teacher_count, g_teacher_ids,
        all_active_member_ids
    }
}
export function getSpaceStats(stats) {
    const spc_stats = stats?.stats;
    //members
    const spc_stats_members = spc_stats?.members;
    const spc_member_curators_ids = spc_stats_members?.curators?.active ? spc_stats_members.curators.active : [];
    const spc_member_curators_count = spc_member_curators_ids?.length
    const spc_member_students = spc_stats_members?.students
    const spc_member_students_ids = spc_member_students?.active ? spc_member_students.active : [];
    const spc_member_students_count = spc_member_students_ids?.length
    const spc_member_teacher_ids = spc_stats_members?.teachers?.active ? spc_stats_members.teachers.active : [];
    const spc_member_teacher_count = spc_member_teacher_ids?.length
    const spc_teachers_curators_count = spc_member_curators_count + spc_member_teacher_count
    const spc_total_member_count = spc_teachers_curators_count + spc_member_students_count
    const spc_teacher_curator_ids = spc_member_curators_ids?.concat(spc_member_teacher_ids);
    const all_active_member_ids = spc_member_curators_ids?.concat(spc_member_students_ids)?.concat(spc_member_teacher_ids)

    const spc_resource_ids = spc_stats?.resources?.spaces_resource_ids ? spc_stats?.resources?.spaces_resource_ids : [];
    const space_votes_per_path = spc_stats?.resources?.votes;


    const spc_stats_member_statuses =
        [
            { label: "active", memberCount: spc_total_member_count },
            { label: "pending", memberCount: 0 },
            { label: "inactive", memberCount: 0 },
            { label: "deleted", memberCount: 0 }
        ]
    //groups
    const spc_stats_groups = spc_stats?.groups ? spc_stats.groups : {};
    const spc_active_group_ids = spc_stats_groups?.active
    const spc_archived_group_ids = spc_stats_groups?.archived

    //paths
    const spc_stats_paths = spc_stats?.paths;
    const spc_paths_active_count = spc_stats_paths?.active_count
    const spc_paths_draft_count = spc_stats_paths?.drafted_count

    //resources

    return {
        //members
        spc_stats_members,
        spc_member_students,
        spc_total_member_count,
        spc_teachers_curators_count,
        spc_teacher_curator_ids,
        all_active_member_ids,
        spc_stats_member_statuses,
        spc_member_curators_ids,
        spc_member_teacher_ids,
        //PATHS
        spc_paths_active_count, spc_stats_paths,

        //groups
        spc_stats_groups, spc_active_group_ids,
        //resources
        spc_resource_ids, space_votes_per_path
    }
}

export function getChannelMemberIDsByRoom(channel_origin, channel_TYPE, group_member_ids, path_member_ids, spc_teacher_curator_ids) {
    let member_ids
    switch (channel_origin) {
        case "PATH":
            member_ids = path_member_ids
            break;
        case "GROUP":
            member_ids = group_member_ids
            break;
        case "SPACE":
            if (channel_TYPE === "SUPPORT" || channel_TYPE === "ANNOUNCEMENTS") {
                member_ids = path_member_ids
            } else if (channel_TYPE === "TEACHERS") {
                member_ids = spc_teacher_curator_ids
            }
            break;
        default:
            member_ids = []
            break;
    }
    return member_ids
}

export function generateGroupDummies(groupCount, groupsStatus = "active") {
    const groupDummies = [...Array(groupCount)]?.map((x, index) => {
        return { metaData: { name: "", id: index.toString(), status: groupsStatus }, properties: { color: "#ccc" } };
    });
    return groupDummies
}